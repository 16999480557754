import React, { ReactNode } from "react";
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  BottomNavigationActionProps,
  BottomNavigationProps,
  Paper,
  styled,
} from "@mui/material";
import { Icon, iconArrowLeft, iconArrowRight, iconList } from "theme/icons";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  theoryChapterState,
  theoryStateChapterNavigationStatus,
  theoryStateChildrenByParentId,
} from "state/theoryState";
import { TreeNode } from "types/cds";
import { testId } from "tests/testIdStrings";
import useRoutePath from "hooks/useRoutePath";
import useChapterActivities from "hooks/useChapterActivities";
import { DialogMenu } from "utils/helpers/DialogMessage";
import useToggle from "hooks/useToggle";
import { TheorySections } from "./sections";
import { TheoryTab } from "utils/helpers/theory/TopNavigationTabs";

const styleFooterPadding = "16px";
const styleFooterBtnSize = "40px";
const styleFooter = {
  position: "fixed",
  top: "auto",
  bottom: styleFooterPadding,
  left: 0,
  right: 0,
  width: "calc(100% - 32px)",
  maxWidth: "1170px",
  overflow: "hidden",
  borderRadius: "30px",
  zIndex: 10,
  marginLeft: "auto",
  marginRight: "auto",
};

const BottomNavigation = styled(MuiBottomNavigation)<BottomNavigationProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 12px",
  height: "64px",
}));

const BottomNavigationAction = styled(MuiBottomNavigationAction)<BottomNavigationActionProps>(
  ({ theme, disabled }) => ({
    color: theme.palette.common.white,
    backgroundColor: disabled ? theme.palette.primary.light01 : theme.palette.primary.main,
    fontSize: "1.1rem",
    borderRadius: "50px",
    minWidth: "auto",
    maxWidth: styleFooterBtnSize,
    height: styleFooterBtnSize,
    ".MuiBottomNavigationAction-label": {
      padding: 0,
    },
  })
);

interface FooterProps {
  children?: ReactNode;
}

export const Footer: React.FC<FooterProps> = (props) => {
  const navigate = useNavigate();
  const path = useRoutePath();
  const [chapterState, setChapterState] = useRecoilState(theoryChapterState);
  const chapters = useRecoilValue(theoryStateChildrenByParentId(chapterState ? chapterState.parentId : -1));
  const { isFirst, isLast } = useRecoilValue(theoryStateChapterNavigationStatus);
  const { setChapterActivities } = useChapterActivities();
  const [openMenu, setOpenMenu] = useToggle(false);

  const handleNavigate = (direction: "next" | "previous") => {
    if (!chapterState) return;

    let chapter: TreeNode | undefined;

    let index = chapters.findIndex((chapter) => chapter.id === chapterState.id);
    chapter =
      index > -1 && index <= chapters.length - 1 ? chapters[direction === "next" ? index + 1 : index - 1] : undefined;

    if (!chapter) return;

    setChapterState(chapter);
    // För learning state
    setChapterActivities(chapter);

    const chapterPath = path
      .replace(":areaId", chapter.parentId.toString())
      .replace(":chapterId", chapter.id.toString());
    navigate(chapterPath);
  };

  return (
    <>
      <Paper sx={styleFooter} elevation={5}>
        <BottomNavigation showLabels={false}>
          <BottomNavigationAction
            icon={<Icon name={iconArrowLeft} />}
            onClick={() => handleNavigate("previous")}
            disabled={isFirst}
            data-testid={testId.menu.bottomActionNavigation.left}
          />
          <BottomNavigationAction
            icon={<Icon name={iconList} />}
            onClick={() => setOpenMenu(true)}
            data-testid={testId.menu.bottomActionNavigation.list}
          />
          <BottomNavigationAction
            icon={<Icon name={iconArrowRight} />}
            onClick={() => handleNavigate("next")}
            disabled={isLast}
            data-testid={testId.menu.bottomActionNavigation.right}
          />
        </BottomNavigation>
      </Paper>

      <DialogMenu title={chapterState!.title} open={openMenu} close={() => setOpenMenu(false)}>
        <TheorySections
          chapterId={chapterState!.id}
          tabName={path.split("/").pop() as TheoryTab}
          menuClose={() => setOpenMenu(false)}
        />
      </DialogMenu>
    </>
  );
};
