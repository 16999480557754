import { useEffect, useState } from "react";

/**
 * A custom hook to get/set localStorage data. Use it as getItem when only "key" is supplied.
 * @param key key to store your value
 * @param initialValue if supplied will set the value on mount. if not you can use the setValue function later to set your value
 * @returns [value, setValue, removeItem]
 */
const useLocalStorage = <T>(key: string, initialValue?: T) => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) return JSON.parse(storedValue) as T;

    return initialValue as T;
  });

  useEffect(() => {
    if (value === undefined) return;

    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  const removeItem = () => {
    localStorage.removeItem(key);
  };

  return [value, setValue, removeItem] as const;
};

export default useLocalStorage;
