import React, { PropsWithChildren, useEffect } from "react";
import { Box, Grid, Stack, Card, useTheme, useMediaQuery, Theme } from "@mui/material";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";
import { CardLink } from "utils/helpers/CardLink";
import { Link } from "react-router-dom";
import { iconArrowCircleRight, iconBookmark, iconClock, iconLightBulbRegular, iconTriangle } from "theme/icons";
import { CircularProgressIncremental } from "utils/helpers/CircularProgressIndicator";
import {
  styleFlex,
  styleFlexAlignItemsCenter,
  styleFlexJustifyContentCenter,
  styleFlexJustifyContentEvenly,
} from "theme/styles";
import { desktopWidth640 } from "theme/theme";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { useAppIntl } from "services/useAppIntl";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { WelcomeMessage } from "utils/helpers/WelcomeMessage";
import { Title } from "utils/helpers/TitleDivider";
import { testId } from "tests/testIdStrings";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { useProgress } from "hooks/useProgress";
import { useRecoilValue } from "recoil";
import { educationState } from "state/educationState";
import { useNavigate } from "react-router-dom";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import ContinueTheory from "./Components/ContinueTheory";
import SvgImage, { svgColor, ellipse1, ellipse2, ellipse4 } from "utils/helpers/SvgImage";
import { HowToUseKKBVideo } from "./more/getStarted";
import RateUsDialog, { getPlatform, PlatformEnum } from "./rateUs";

const imgNoProgress = process.env.PUBLIC_URL + "/img/illustrations/test-failure.svg";
const imgPerson = process.env.PUBLIC_URL + "/img/illustrations/person-whiteshirt.svg";

export const Home: React.FC = () => {
  const intl = useAppIntl();
  const routes = useRouteConfiguration();
  const navigate = useNavigate();
  const useBookmarks = useFeatureFlag("Bookmarks");
  const useRateUs = useFeatureFlag("RateUs");
  const currentEducationIdentifier = useRecoilValue(educationState)?.identifier;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const platform = getPlatform();

  useEffect(() => {
    if (!currentEducationIdentifier) navigate(routes.education.path);
  }, [currentEducationIdentifier, navigate, routes.education.path]);

  useEffect(() => {
    if (!localStorage.getItem("showConsentWarning")) localStorage.setItem("showConsentWarning", "true");
  });

  if (!currentEducationIdentifier) return null;

  return (
    <>
      <BackgroundShape top={-200} left={isDesktop ? "12%" : -100}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>
      <BackgroundShape top={isDesktop ? 250 : 450} right={-450}>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="530" height="360" color={svgColor.blue3} />
      </BackgroundShape>
      {useRateUs && platform !== PlatformEnum.Desktop && <RateUsDialog />}
      <Box sx={{ position: "relative", maxWidth: desktopWidth640, marginLeft: "auto", marginRight: "auto" }}>
        <WelcomeMessage />

        <Progress />

        <Title title={intl.formatMessage({ id: "common.title.theory" })} variant="h2" />
        <Stack spacing={3} mb={3}>
          <ContinueTheory />
          <NavigationItem
            size="large"
            href={routes.roadsigns.path}
            text={intl.formatMessage({ id: "common.roadsigns" })}
            icon={iconTriangle}
            navigationIcon={iconArrowCircleRight}
            dataTestIdString={testId.listItem.home.roadsigns}
          />
          {useBookmarks && (
            <NavigationItem
              size="large"
              href={routes.bookmarks.path + "#home"}
              text={intl.formatMessage({ id: "more.title.bookmarksandnotes" })}
              icon={iconBookmark}
              navigationIcon={iconArrowCircleRight}
              dataTestIdString={testId.listItem.home.bookmark}
            />
          )}
        </Stack>

        <Title title={intl.formatMessage({ id: "common.title.test" })} variant="h2" />
        <Stack spacing={3}>
          <NavigationItem
            size="large"
            text={intl.formatMessage({ id: "test.type.study.quick" })}
            icon={iconClock}
            navigationIcon={iconArrowCircleRight}
            dataTestIdString={testId.listItem.home.testTodaysQuestion}
            href={routes.test.path + "/quick/study"}
          />
        </Stack>
      </Box>

      <InfoBox>
        <FormattedTypography id="home.reach-goal" variant="subtitle1" sx={{ fontWeight: 600 }} />
        <FormattedTypography id="home.study.requirements" maxWidth={"150px"} variant="caption" />
      </InfoBox>
      <BackgroundShape bottom={-400} center stickToBottom>
        <SvgImage d={ellipse2.d} viewBox={ellipse2.viewBox} width="570" height="410" color={svgColor.turquoise} />
      </BackgroundShape>
    </>
  );
};

const InfoBox = ({ children }: PropsWithChildren) => {
  return (
    <Box sx={[styleFlex, styleFlexJustifyContentCenter, { position: "relative" }]} mt={5} mb={5}>
      <Box mt={-4}>
        <SvgImage d={ellipse4.d} viewBox={ellipse4.viewBox} width="420" height="240" color={svgColor.pink} />
      </Box>
      <Box sx={{ position: "absolute", top: "-1rem", width: "396px" }}>
        <Box sx={[styleFlex, styleFlexAlignItemsCenter, styleFlexJustifyContentEvenly]} pl="3">
          <Box>
            <img src={imgPerson} alt="Person walking" />
          </Box>
          <Box sx={{ marginLeft: "-55px", marginTop: "10px" }}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const Progress = () => {
  const { knowledgeLevelUser } = useProgress();
  const theme = useTheme();
  const intl = useAppIntl();
  const progress = knowledgeLevelUser ?? 0;

  const styleProgressText = { display: "inline-block", textAlign: "center" };
  const styleProgressLink = { color: theme.palette.primary.dark06, textDecoration: "none", fontStyle: "italic" };

  if (!knowledgeLevelUser) return null;

  return (
    <Box mb={3}>
      <Title
        sx={{ marginTop: progress >= 10 ? 8 : 4 }}
        title={<FormattedMessage id="common.title.progress" />}
        variant="h2"
      />
      <Grid container wrap="nowrap" spacing={1} mb={3}>
        <Grid item xs={12}>
          {progress !== 0 ? (
            <CardLink to={"progress"} dataTestIdString={testId.link.home.percent}>
              <CircularProgressIncremental value={progress} />
              <FormattedTypography
                id="progress.knowledgelevel.total"
                variant="caption"
                sx={{ width: "100%", display: "inline-block", textAlign: "center" }}
              />
            </CardLink>
          ) : (
            <Card sx={{ display: "flex", justifyContent: "center", flexDirection: "column", padding: 2, marginTop: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={imgNoProgress} height={"150px"} alt="No progress" />
              </Box>
              <FormattedTypography
                id="progress.zeroprogress.line1"
                values={{ percent: progress }}
                variant="caption"
                sx={styleProgressText}
              />

              <FormattedTypography
                id="progress.zeroprogress.line2"
                values={{
                  theoryLink: (
                    <Link style={styleProgressLink} to="/theory">
                      <FormattedMessage id="progress.answertheory.link" />
                    </Link>
                  ),
                }}
                variant="caption"
                sx={styleProgressText}
              />
              <FormattedTypography
                id="progress.zeroprogress.line3"
                values={{
                  testLink: (
                    <Link style={styleProgressLink} to="/test">
                      <FormattedMessage id="common.title.test" />
                    </Link>
                  ),
                }}
                variant="caption"
                sx={styleProgressText}
              />
            </Card>
          )}
        </Grid>
      </Grid>
      {progress < 10 && (
        <>
          <Title title={intl.formatMessage({ id: "common.title.getstarted" })} variant="h2" />
          <HowToUseKKBVideo subTextVariant="body2" icon={iconLightBulbRegular} iconSize="large" />
        </>
      )}
    </Box>
  );
};
