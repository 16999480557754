import { Box, Button, ButtonBase, Dialog } from "@mui/material";
import ReactGA from "react-ga4";
import { Icon, iconClose } from "theme/icons";
import { optimaColors } from "theme/optima";
import { FormattedMessage } from "react-intl";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import useLocalStorage from "hooks/useLocalStorage";
import { useEffect } from "react";

export type RateUsDialogType = {
  step?: string;
  status?: string;
};

export enum PlatformEnum {
  IOS = "iOS",
  Android = "Android",
  Desktop = "Desktop",
}

export enum ShowRateUsDialog {
  First = "First",
  Second = "Second",
  Third = "Third",
}
export enum RateUsDialogStatus {
  DismissedFirst = "DismissedFirst",
  DismissedSecond = "DismissedSecond",
  DismissedThird = "DismissedThird",
  Fulfilled = "Fulfilled",
}

export function getPlatform(): string {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return PlatformEnum.IOS;
  if (/android/i.test(userAgent)) return PlatformEnum.Android;

  return PlatformEnum.Desktop;
}

export function rateUs() {
  const platform = getPlatform();
  ReactGA.event({
    category: "rateUs",
    action: "rateUsDialogShown",
    label: "rateUsYes",
  });

  if (platform === PlatformEnum.IOS)
    window.location.href = "https://apps.apple.com/se/app/k%C3%B6rkortsboken/id6450051169?action=write-review";

  if (platform === PlatformEnum.Android)
    window.open("https://play.google.com/store/apps/details?id=se.str.korkortsboken", "_blank");
}

const RateUsDialog = () => {
  const imgRecension = process.env.PUBLIC_URL + "/img/illustrations/recension.svg";

  const [rateUsDialog, setRateUsDialog] = useLocalStorage<RateUsDialogType>("rateUs", { step: "", status: "" });
  const { step, status } = rateUsDialog;

  console.log("rateUsDialog", rateUsDialog);

  useEffect(() => {
    console.log("rateUsDialog uef", rateUsDialog);
  }, [rateUsDialog]);

  const showDialog = () => {
    const firstShow = step === ShowRateUsDialog.First && status !== RateUsDialogStatus.DismissedFirst;
    const secondShow = step === ShowRateUsDialog.Second && status !== RateUsDialogStatus.DismissedSecond;
    const thirdShow = step === ShowRateUsDialog.Third && status !== RateUsDialogStatus.DismissedThird;

    console.log("rateUsDialog showDialog", rateUsDialog);

    if (status === RateUsDialogStatus.Fulfilled) return false;

    if (firstShow || secondShow || thirdShow) return true;

    return false;
  };

  const goToRating = () => {
    setRateUsDialog({ ...rateUsDialog, status: RateUsDialogStatus.Fulfilled });
    rateUs();
  };

  const dismissDialog = () => {
    ReactGA.event({
      category: "rateUs",
      action: "rateUsDialogShown",
      label: "rateUsNo",
    });

    switch (step) {
      case ShowRateUsDialog.First:
        setRateUsDialog({ ...rateUsDialog, status: RateUsDialogStatus.DismissedFirst });
        break;
      case ShowRateUsDialog.Second:
        setRateUsDialog({ ...rateUsDialog, status: RateUsDialogStatus.DismissedSecond });
        break;
      case ShowRateUsDialog.Third:
        setRateUsDialog({ ...rateUsDialog, status: RateUsDialogStatus.DismissedThird });
        break;
    }
  };

  if (!showDialog()) return null;

  return (
    <Dialog
      fullWidth
      open
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
      }}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          background: optimaColors.tertiary07,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <FormattedTypography color="white" sx={{ fontSize: 20, fontWeight: 600 }} id="rateus.dialog.header-text" />
          <ButtonBase sx={{ fontSize: 20, color: "white", fontWeight: 900 }} onClick={() => dismissDialog()}>
            <Icon name={iconClose} htmlColor={"white"} size={"large"} />
          </ButtonBase>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <img src={imgRecension} height={220} alt="Person thinking about 5 star rating" />
        </Box>
        <Button
          variant="contained"
          color="inherit"
          sx={{
            background: "white",
            color: optimaColors.tertiary07,
            width: "100%",
            alignItems: "center",
            margin: "auto",
            marginTop: 4,
            fontSize: 14,
          }}
          onClick={() => goToRating()}
        >
          <FormattedMessage id="rateus.dialog.button-yes" />
        </Button>
        <Button
          sx={{ color: "white", border: "1px solid grey", marginTop: 2, marginBottom: 1, fontSize: 14 }}
          onClick={() => dismissDialog()}
        >
          <FormattedMessage id="rateus.dialog.button-no" />
        </Button>
      </Box>
    </Dialog>
  );
};

export default RateUsDialog;
