import { EventType, InteractionStatus, InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { ADB2C_ERROR_CODES, b2cPolicies } from "authConfig";
import useLocalStorage from "hooks/useLocalStorage";
import React, { PropsWithChildren, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { passwordChangedSuccess } from "state/userState";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { NEW_ACCOUNT_PATH } from "hooks/useRouteConfiguration";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useLogout } from "hooks/useLogout";
import { Box } from "@mui/material";

interface ILoginHandlerProps {
  instance: IPublicClientApplication;
}
type loginStatus =
  | "authenticated"
  | "unauthenticated"
  | "none"
  | "loginFailure"
  | "passwordReset"
  | "passwordResetCancel";
export const LoginHandler: React.FC<PropsWithChildren<ILoginHandlerProps>> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [status, setStatus] = React.useState<loginStatus>("none");
  const [lsChangePassword, , removeLsChangePassword] = useLocalStorage<boolean>("profileChangePassword");
  const setPasswordChanged = useSetRecoilState(passwordChangedSuccess);
  const [, setShowLsRegisterEducatorWarning] = useLocalStorage("showRegisterEducatorWarning");
  const { logout } = useLogout();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginFailure = async (event: any) => {
    ReactGA.event({
      category: "login",
      action: "loginFailure",
    });

    if (event.error.errorMessage.includes(ADB2C_ERROR_CODES.USER_CANCELLATION)) {
      setStatus("passwordResetCancel");
      return await instance.loginRedirect();
    }

    if (
      event.error.errorMessage.includes(ADB2C_ERROR_CODES.FORGOTTEN_PASSWORD) &&
      event.interactionType === InteractionType.Redirect
    ) {
      setStatus("passwordReset");
      return await instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
    }

    setStatus("loginFailure");
    return await logout();
  };

  const handleLoginSuccess = async (event: any) => {
    ReactGA.event({
      category: "login",
      action: "loginSuccess",
    });
    if (event?.payload) {
      /**
       * We need to reject id tokens that were not issued with the default sign-in policy.
       * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
       * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
       */
      if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) {
        /**
         * Check for key in localStorage.
         * If found we are coming from "change password" on Profile page
         * and we can (safely) remain logged in. If not, force a logout
         */
        if (!lsChangePassword) return await logout("forgotPassword");

        removeLsChangePassword();
        setPasswordChanged(true);
        return;
      }

      if (event.payload.account) {
        // Since the user only has one account, set that to active
        instance.setActiveAccount(event.payload.account);
      } else {
        const account = instance.getActiveAccount();

        // redirect anonymous user to login page
        if (!account) await instance.loginRedirect();
      }

      /**
       * If it's a new user
       */
      if (event.payload.idTokenClaims["newUser"]) return navigate(NEW_ACCOUNT_PATH);

      /**
       * Existing user -> navigate to start (which decides the initial page for the user)
       */
      return navigate("/start");
    }
  };

  useEffect(() => {
    if (isAuthenticated) setStatus("authenticated");
    else setStatus("unauthenticated");
  }, [isAuthenticated]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        return await handleLoginFailure(event);
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        setShowLsRegisterEducatorWarning(true);

        return await handleLoginSuccess(event);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        if (event.error?.errorMessage?.includes(ADB2C_ERROR_CODES.USER_CANCELLATION)) {
          // If we get here user has canceled password reset from my profile page
          // and we don't want to take action on that
          return;
        }

        return await logout();
      }
    });

    return () => {
      if (callbackId) instance.removeEventCallback(callbackId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When we currently are in progress we don't want to start the app
  if (
    inProgress !== InteractionStatus.None ||
    status === "none" ||
    status === "loginFailure" ||
    status === "passwordReset" ||
    status === "passwordResetCancel"
  ) {
    if (process.env.NODE_ENV === "development")
      console.log("LoginHandler: Msal in progress...", inProgress, status, location.hash);

    // TODO: Vi kan inte använda useAppIntl här (och översättningar), då den inte är tillgänglig än. Måste hitta en annan lösning.

    let content = "Autentiserar...";
    if (inProgress === InteractionStatus.Startup) content = "Initierar...";
    if (inProgress === InteractionStatus.Login) content = "Loggar in...";
    if (inProgress === InteractionStatus.Logout || location.hash === "#logout") content = "Loggar ut...";

    if (status === "passwordReset") content = "Vidarebefordrar till återställ lösenord...";
    if (status === "passwordResetCancel") content = "Avbryter återställning av lösenord...";
    if (status === "loginFailure") content = "Inloggning misslyckades...";

    return (
      <Box id="init">
        <Box className="content">{content}</Box>
      </Box>
    );
  }

  return <>{children}</>;
};
