import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { learningStructureState } from "state/learningStructureState";
import { educationStateReferenceKey } from "state/educationState";
import { activityProgressState } from "state/progresState";
import { useAccount } from "@azure/msal-react";
import { getProgressReport } from "services/lrsService";
import { useAssessment } from "./useAssessment";
import {
  ItemConfigurationTypeKnowledge,
  ItemTypeKnowledge,
  ItemKnowledgeDetail,
  ItemTitleValuePair,
} from "services/reportingService";
import useLocalStorage from "./useLocalStorage";
import { RateUsDialogStatus, RateUsDialogType, ShowRateUsDialog } from "layout/rateUs";

/**
 * Hook for getting progress data from the current education
 */
export const useProgress = () => {
  const account = useAccount();
  const [knowledgeLevels, setKnowledgeLevels] = useState<undefined | ItemTitleValuePair[]>();
  const [activityProgress, setActivityProgress] = useRecoilState<undefined | ItemTitleValuePair[]>(
    activityProgressState
  );
  const [knowledgeLevelUser, setKnowledgeLevelUser] = useState<undefined | number>();
  const learningStructure = useRecoilValue(learningStructureState);
  const { assessment: assessmentMaster } = useAssessment({
    type: "study",
    key: useRecoilValue(educationStateReferenceKey)!,
  });

  // Rate us dialog
  const [rateUsDialog, setRateUsDialog] = useLocalStorage<RateUsDialogType>("rateUs", { step: "", status: "" });
  const { status } = rateUsDialog;
  const lsProgress = localStorage.getItem("lsProgress");
  // Rate us dialog

  useEffect(() => {
    const referenceKey = learningStructure?.find((x) => x.parentId === 0)?.activityKey;
    if (!assessmentMaster || !account || !referenceKey) return;

    const getProgress = async () => {
      const educationProgress = await getProgressReport(assessmentMaster, account.localAccountId, referenceKey);

      if (!educationProgress || !educationProgress.items || !educationProgress.items.length)
        throw new Error("No progress data found");

      const knowledgeLevels = educationProgress.items.filter(
        (item: any) =>
          item.type === ItemTypeKnowledge.KnowledgeLevel &&
          item.configuration.name === ItemConfigurationTypeKnowledge.DetailA
      ) as ItemKnowledgeDetail[];
      setKnowledgeLevels(knowledgeLevels.length ? knowledgeLevels[0].items : []);

      const activityProgress = educationProgress.items.filter(
        (item: any) =>
          item.type === ItemTypeKnowledge.KnowledgeLevel &&
          item.configuration.name === ItemConfigurationTypeKnowledge.DetailB
      ) as ItemKnowledgeDetail[];
      setActivityProgress(activityProgress.length ? activityProgress[0].items : []);

      const knowledgeLevel = educationProgress.items.filter(
        (item: any) =>
          item.type === ItemTypeKnowledge.KnowledgeLevel &&
          item.configuration.name === ItemConfigurationTypeKnowledge.OverviewA
      );
      const value = knowledgeLevel[0].items[0].value;
      let progress = parseInt(value === "" ? "0" : value);

      // if (process.env.NODE_ENV === "development" || window.location.hostname === "korkortsboken.dev.str.se")
      //   if (lsProgress) {
      //     console.log("lsProgress found...overriding user progress", lsProgress);
      //     progress = parseInt(lsProgress);
      //   }

      setKnowledgeLevelUser(progress);
    };

    getProgress();
  }, [assessmentMaster, learningStructure, account, setActivityProgress, lsProgress]);

  // Rate us dialog
  useEffect(() => {
    if (!knowledgeLevelUser) return;

    console.log("update rate us dialog", knowledgeLevelUser, status);

    // Update rate us dialog
    if (status === RateUsDialogStatus.Fulfilled) return;

    if (knowledgeLevelUser >= 40 && knowledgeLevelUser < 70 && status !== RateUsDialogStatus.DismissedFirst) {
      setRateUsDialog({ step: ShowRateUsDialog.First });
    } else if (knowledgeLevelUser >= 70 && knowledgeLevelUser < 100 && status !== RateUsDialogStatus.DismissedSecond) {
      setRateUsDialog({ step: ShowRateUsDialog.Second });
    } else if (knowledgeLevelUser === 100 && status !== RateUsDialogStatus.DismissedThird) {
      setRateUsDialog({ step: ShowRateUsDialog.Third });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeLevelUser, status]);

  return { knowledgeLevels, activityProgress, knowledgeLevelUser };
};
